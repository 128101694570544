import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import DateFnsUtils from "@date-io/date-fns";
import { ThemeProvider } from "styled-components/macro";
import { create } from "jss";

import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import {
  StylesProvider,
  ThemeProvider as MuiThemeProvider,
  jssPreset,
} from "@material-ui/core/styles";

import createTheme from "./theme";
import Routes from "./routes/Routes";
///
import { SnackbarProvider, useSnackbar } from 'notistack';
import { signOut } from "./redux/actions/authActions";

///
const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById("jss-insertion-point"),
});

function App() {
  const version = "3.0.3";
  let localVersion = localStorage.getItem("version");
  const theme = useSelector((state) => state.themeReducer);

  useEffect(() => {
    console.log = () => {}
  }, [])

  useEffect(async () => {
    console.log("check version", localVersion, version);
    if (localVersion && localVersion != version) {
      console.log("clearing local storage");
      signOut()
      localStorage.clear();
      localStorage.setItem("version", version);
    } else if (!localVersion) {
      localStorage.clear();
      localStorage.setItem("version", version);
    }
  }, []);

  return (
    <React.Fragment>
      <Helmet
        titleTemplate="%s | Haelf"
        defaultTitle="Haelf Admin Dashboard"
      />
      <StylesProvider jss={jss}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <MuiThemeProvider theme={createTheme(theme.currentTheme)}>
            <ThemeProvider theme={createTheme(theme.currentTheme)}>
              <SnackbarProvider 
                maxSnack={3}
                anchorOrigin={{
                  horizontal: 'right', 
                  vertical: 'top'
                }}
              >
                <Routes />
              </SnackbarProvider>            
            </ThemeProvider>
          </MuiThemeProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </React.Fragment>
  );
}

export default App;

import { Box, Container, Divider, Grid, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux';
import styled, { withTheme } from "styled-components/macro";
import { get, URL } from '../../services';

const Heading = styled.div`
    border:1px solid ;
    height:3rem;
    margin-bottom:12px;
    margin-top:12px;
    background-color:${(props) => props.theme.header.background};
    display:flex;
    align-items:center;
    & > h4{
        padding-left:10px;
        margin:0px
    }
`;

const GridBox = styled(Grid)`
    border:1px solid;
    padding:20px;
    // margin-top:12px;
`;

function AnalyticaComp() {
    const [analyics, setanalyics] = useState({})
    const lang = useSelector(state => state.authReducer.lang)
    useEffect(() => {
       getAnalytics()
    }, [])

    const getAnalytics = () =>{
        let dataToSend ={ lang }
        get(URL.getAnalytics,dataToSend).then(res=>{
            if(res.status){
                console.log('res',res)
                setanalyics(res.data)
            }
        }).catch(err=>{
            console.log('error getting analytics : ',err)
        })
    }

    return (
        <Box>
            <Container>
                <Helmet title="Analytics Dashboard" />
                    <Grid justify="space-between" container spacing={6}>
                        <Grid item>
                        <Typography
                            style={{ color: "rgb(17, 24, 39)" }}
                            variant="h3"
                            gutterBottom
                        >
                            Dashboard Analytics
                        </Typography>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Divider style={{ color: "rgb(17, 24, 39)" }} my={6} />
                    </Grid>
                   {/* dropdowns */}
                   <Box style={{marginTop:40}}>
                        <Grid container spacing={1} justify='space-between'>
                            <Grid item sm={6}>
                                <Heading>
                                    <h4>Sales</h4>
                                </Heading>
                                <Grid container  justify='space-between'>
                                    <GridBox item sm={5}>
                                        <p>Gross Sales</p>
                                        <h5>$ {analyics?.sales?.gross_amount ? parseFloat(analyics?.sales?.gross_amount).toFixed(2) : 0}</h5>
                                    </GridBox>
                                    <GridBox item sm={5}>
                                        <p>Net Sales</p>
                                        <h5>$ {analyics?.sales?.net_amount ? parseFloat(analyics?.sales?.net_amount).toFixed(2) : 0}</h5>

                                    </GridBox>
                                </Grid>
                            </Grid>
                            <Grid item sm={6}>
                                <Heading>
                                    <h4>ORDERS</h4>
                                </Heading>
                                <Grid container  justify='space-between'>
                                    <GridBox item sm={5}>
                                        <p>Total Orders</p>
                                        <h5>{analyics?.orders?.total_orders ? analyics?.orders?.total_orders : 0}</h5>
                                    </GridBox>
                                    <GridBox item sm={5}>
                                        <p>Orders on Hold</p>
                                        <h5>{analyics?.orders?.orders_onhold ? analyics?.orders?.orders_onhold : 0}</h5>

                                    </GridBox>
                                </Grid>
                            </Grid>
                        </Grid>

                        {/* products */}
                        <Heading><h4>PRODUCTS</h4></Heading>
                        <Grid container justify='space-between'>
                           
                                <Grid item sm={6}>
                                    <Grid container justify='space-around'>
                                        <GridBox item sm={5}>
                                            <p>Low in Stock</p>
                                            <h5>0 Products</h5>
                                        </GridBox>
                                            <GridBox item sm={5}>
                                            <p>Out of Stocks Sales</p>
                                            <h5>0 Products</h5>
                                        </GridBox>
                                    </Grid>
                                </Grid>
                                <Grid item sm={6}>
                                    <Grid container justify='space-around'>
                                    <GridBox item sm={5}>
                                        <p>The most selling product</p>
                                        <h5>{analyics?.topProduct?.name ? analyics?.topProduct?.name[lang] : '-'}</h5>
                                    </GridBox>
                                        <GridBox item sm={5}>
                                        <p>The most selling product</p>
                                        <h5>{analyics?.topGroup?.name ? analyics?.topGroup?.name[lang] : '-'}</h5>
                                    </GridBox>
                                </Grid>
                                </Grid>
                        </Grid>
                        
                        <Grid container spacing={1} justify='space-between'>
                            <Grid item sm={6}>
                                <Heading>
                                    <h4>RENEWALS</h4>
                                </Heading>
                                <Grid container  justify='space-between'>
                                    <GridBox item sm={5}>
                                        <p>Subcripition Renewals</p>
                                        <h5>6</h5>
                                    </GridBox>
                                    <GridBox item sm={5}>
                                        <p>Renewal Revenue </p>
                                        <h5>$ 448.22</h5>

                                    </GridBox>
                                </Grid>
                            </Grid>
                            <Grid item sm={6}>
                                <Heading>
                                    <h4>SIGNUPS</h4>
                                </Heading>
                                <Grid container  justify='space-between'>
                                    <GridBox item sm={5}>
                                        <p>Subscriptions Signups</p>
                                        <h5>20</h5>
                                    </GridBox>
                                    <GridBox item sm={5}>
                                        <p>Signup Revenue</p>
                                        <h5>$ 1.054.08</h5>

                                    </GridBox>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* <Grid container>
                            <GridBox item sm={5}>
                                <p>Low in Stock</p>
                                <h5>0 Products</h5>
                            </GridBox>
                                <GridBox item sm={5}>
                                <p>Out of Stocks Sales</p>
                                <h5>0 Products</h5>
                            </GridBox>
                        </Grid> */}
                        {/* <Grid container>
                            <GridBox item sm={5}>
                                <p>Low in Stock</p>
                                <h5>0 Products</h5>
                            </GridBox>
                                <GridBox item sm={5}>
                                <p>Out of Stocks Sales</p>
                                <h5>0 Products</h5>
                            </GridBox>
                        </Grid> */}
                   </Box>

            </Container>
        </Box>
    )
}

export default AnalyticaComp

// import * as types from "../../constants";

const initialState = {
  user: null,
  token: "",
  lang: "en",
  isLogin: false,
};
export default function User(state = initialState, action) {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        user: action.user,
        token: action.token,
        isLogin: true,
      };
    case 'LOGOUT':
      return {
        ...state,
        token: "",
        user: null,
        isLogin: false,
        lang: 'en'
      };
    default:
      return state;
  }
}

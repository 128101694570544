import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

import rootReducer from "../reducers/index";

const persistConfig = {
  key: "root",
  storage,
  timeout: null,
  //blacklist
};
const composeEnhancers = composeWithDevTools({});
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(thunk))
);

const persistedStore = persistStore(store);

export { persistedStore, store };
 
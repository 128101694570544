import React,{useState,useEffect} from "react";
import styled from "styled-components/macro";

import { Helmet } from "react-helmet";
import MaterialTable from "material-table";
import {
  Grid,
  Divider as MuiDivider,
  Typography as MuiTypography,
  Button,
  TextField,
  makeStyles,
  Switch,
   InputLabel,
  // Input,
  ButtonGroup,
  FormHelperText,
  FormControl,
  Select,
  MenuItem,
  DropDownMenu,
  SelectChangeEvent,
  Modal,
  Box,
} from "@material-ui/core";
import {
  useLocation
} from 'react-router-dom'
import "../../pages/dashboards/SaaS/index.css";
import { spacing } from "@material-ui/system";
import { Redirect, useHistory } from "react-router-dom";
 
import plus2 from '../../pages/dashboards/assets/plus2.png'; 
import delete_icon from '../../pages/dashboards/assets/delete_icon.png';
import Actions from "../../pages/dashboards/Analytics/Actions"; 
// import {URL,get,post,headers} from '../../../services'
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { get,URL, headers, post } from "../../services";
const Divider = styled(MuiDivider)(spacing);

const Typography = styled(MuiTypography)(spacing);
const style = {
  position: 'absolute',
  padding:"40px",
  top: '50%',
  backgroundColor:"white",
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  // bgcolor: 'background.paper',
  border: '1px solid blue',
  boxShadow: 24,
  p: 4,
};
function SubGroupDet() {
  const location = useLocation()
  const history = useHistory();
  const [type, settype] = useState(location?.state?.type)
  const [groupData, setGroupData] = useState(location?.state?.data)
  const [select,setselect] = useState(true);
  const [data,setdata]=useState([])
  const [counter,setcounter]=useState([])
  const [open, setOpen] = React.useState(false);
  const [nameen, setnameen] = React.useState("");
  const [namege, setnamege] = React.useState("");
  const [descen, setdescen] = React.useState("");
  const [descge, setdescge] = React.useState("");
  const [selectgrp, setselectgrp] = React.useState([]);
  const [selected, setselected] = React.useState('');
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  console.log('dataaaa', location.state.data._id)

  const useStyles = makeStyles(theme => ({
    input: {
      "&::placeholder": {
        color: "#233044",
        textAlign: "left"
      }
    },
  }));
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const label = { inputProps: { "aria-label": "Switch demo" } };
  
  var userdata= useSelector((data)=>{
    return data
  })
  console.log(userdata.authReducer.lang,"userdata")

  useEffect(() => {
    console.log('type data',groupData)
    if(location.state){ 
      getData()   
      getSubGroupByID()   
    }
  }, [])

  const getData = async () =>{
    if(type == 0){
      // get subgroups
      await getSubs()
    }else{
      await getProducts()
    }
  }

  const getSubGroupByID = async () => { 
    let data = location.state;
    const dataToSend = {
      subGroup_id: location.state.data._id,
      lang: 'en'
    }
    console.log('data to send', dataToSend)  
    await get(URL.getSubGroupByID,dataToSend).then((res)=> {
      console.log('dataaaa', res.data);
      setnameen(res.data?.name?.en)
      setnamege(res.data?.name?.de)
      if(type != 0){
        setdescen(res.data?.description?.en)
        setdescge(res.data?.description?.de)
      }
    })
    .catch((e) => console.log('Error', e))
  }

  const getSubs = async () =>{
    let dataToSend={
      lang:'en',
      group_id:groupData._id,
      location:'UK'
    }
      await get(URL.getsubgroup,dataToSend).then(res=>{
        // enqueueSnackbar(response.msg,{
        //   variant:'success'
        // })
        setdata(res.data)

      console.log('subs res',res)
      }).catch(err=>{
        console.log('sub err',err)
      })
  }
//   console.log('sub',data)
  const getProducts = async () =>{
    let dataToSend ={ 
      lang:'en',
      location:'all',
      subgroup_id:groupData?._id,
      offset: 0,
      per_page: 100,
    }
    await get(URL.getproductlist,dataToSend).then(res=>{
      // enqueueSnackbar(response.msg,{
      //   variant:'success'
      // })
      console.log('product res',res)
      setdata(res.data)
    }).catch(err=>{
      console.log('prod err',err)
    })
  }

  const submit = ()=>{
    if(!nameen || !namege) return enqueueSnackbar('Name is required ',{
      variant:'error'
    })
    let dataToSend = {
      id:groupData._id,
    }
    if(type == 0){
      dataToSend.name = {
        en:nameen,
        de:namege
      }
      editGroup(dataToSend)
    }else{
      if(!descen || !descge) return enqueueSnackbar('Description is required ',{
        variant:'error'
      })
      dataToSend.data = {
        name : {
          en:nameen,
          de:namege
        },
        description:{
          en:descen,
          de:descge
        }
      }
      editSubGroup(dataToSend)
    }
  }

  const editGroup = async (data) =>{
    await post(URL.editGroup,data).then(res=>{
      enqueueSnackbar(res.msg,{
        variant:'success'
      })
      console.log(res)
    }).catch(err=>{
      const msg = err?.msg || 'Somthing went wrong'
      enqueueSnackbar(msg,{
        variant:'error'
      })
    })
  }
 
  const editSubGroup = async (data) =>{
    await post(URL.editSubGroup,data).then(res=>{
      enqueueSnackbar(res.msg,{
                variant:'success'
      })

      console.log(res)
    }).catch(err=>{
      console.log(err)
      const msg = err?.msg || 'Somthing went wrong'
      enqueueSnackbar(msg,{
        variant:'error'
      })
    })
  }

  const deleteSub = async (sub) =>{
    console.log('sub',sub)
    if(sub._id){
      let dataToSend={
        subgroup_id:sub._id
      }
      await post(URL.deleteSubGroup,dataToSend).then(res=>{
        getSubGroups()
      }).catch(err=>{
        let messsage = err?.msg || 'Something went wrong'
        enqueueSnackbar(messsage,{
          variant:'error'
        })
      })
    }
  } 

  const handleEdit = (data) =>{
    let dataToSend ={
      type:1,
      data
    }
    console.log(data); 
    history.push({
      pathname: "/components/productdetails",
      state: data,
    })
}
  return (
    <React.Fragment>
      <Helmet title="SaaS Dashboard" />
      <Grid justify="space-between" container spacing={6}>
        <Grid item>
          <Typography variant="h3" style={{color:"#233044"}} gutterBottom>
            {location?.state?.data?.row?.name?.en} 
          </Typography> 
        </Grid> 
        <Grid item>
          {/* <Actions /> */}
        </Grid>
      </Grid>

      <Divider my={6} />
      <Grid justify="space-between" container spacing={6}>
        <Grid item>
          <Typography variant="h3" style={{color:"#233044"}} gutterBottom>
            1.Details
          </Typography>
        </Grid>

        <Grid item>
        {/* <Button variant="outlined" style={{backgroundColor:"#F7F9FC",width:"140px",height:"40px",border:"1px solid #233044"}}>  
          <h6 style={{color:"#233044",marginTop:"9px",display:"flex",justifyContent:"flex-start",alignItems:"flex-start"}}>EDIT</h6> 
          <img src={edit} style={{width:"17px",color:"#233044",height:"17px",marginLeft:"20px"}} />
          </Button> */}
        </Grid>
      </Grid>
      <Divider my={6} />
     {/* <form novalidate> */}
      <Grid className="mb-3" spacing={6}>
         <div className="mb-3">
          <label htmlFor="exampleFormControlInput1" className="form-label" style={{color:"#233044"}}>Name UK</label>
          <input required type="text" className="form-control" value={nameen} onChange={(e)=>setnameen(e.target.value)} id="exampleFormControlInput1" placeholder="Sex Health" />
        </div>
      </Grid>
      <Grid className="mb-3" spacing={6}>
         <div className="mb-3">
          <label htmlFor="exampleFormControlInput1" className="form-label" style={{color:"#233044"}}>Name DE</label>
          <input required type="text" className="form-control" value={namege} onChange={(e)=>setnamege(e.target.value)} id="exampleFormControlInput1" placeholder="Sex Health" />
        </div>
      </Grid>
      {
        type !== 0 && <Grid className="mb-3" spacing={6}>
            <div className="mb-3">
                <label htmlFor="exampleFormControlInput1" className="form-label" style={{color:"#233044"}}>Sub Group Description UK</label>
                <textarea required className="form-control" value={descen} onChange={(e)=>setdescen(e.target.value)} id="exampleFormControlTextarea1" placeholder="Slidenafil come in 50 mg strenght. It helps relax blood flow to your penis to allow men to get and keep an erection after sexual stimulation." rows="3"></textarea>
              </div>
        </Grid>
      }
      {
        type !== 0 && <Grid className="mb-3" spacing={6}>
        <div className="mb-3">
                <label htmlFor="exampleFormControlInput1" className="form-label" style={{color:"#233044"}}>Sub Group Description DE</label>
                <textarea required className="form-control" value={descge} onChange={(e)=>setdescge(e.target.value)} id="exampleFormControlTextarea1" placeholder="Slidenafil come in 50 mg strenght. It helps relax blood flow to your penis to allow men to get and keep an erection after sexual stimulation." rows="3"></textarea>
              </div>
        </Grid>
      }
     <Button variant='contained' color='primary' onClick={()=>submit()} type="submit">SAVE CHANGES</Button>
      {/* <button type="button" className="btn btn-secondary second-button">CANCEL</button> */}
    {/* </form> */}
    <br></br><br></br><br></br><br></br>
      <Grid justify="space-between" container spacing={6}>
        <Grid item>
          <Typography variant="h3" style={{color:"#233044"}} gutterBottom>
          2. What {nameen} Contains
          </Typography>
        </Grid>

        <Grid item>
        {type == 0 && <Button variant="outlined" className="add_new" style={{backgroundColor:"#233044",width:"300px",border:"1px solid #233044"}}>  
        <img src={plus2} style={{width:"17px",color:"#233044",height:"17px"}} />
          <h6 onClick={handleOpen} style={{color:"white",marginTop:"9px",display:"flex",marginLeft:"14px",justifyContent:"flex-start",alignItems:"flex-start"}}>ADD NEW SUB GROUP</h6> 
          </Button>} 
        </Grid>
      </Grid>
      <Divider my={6} />
      <div style={{ maxWidth: "100%" }}>
        <MaterialTable
          columns={[ 
            { title: "PRODUCT NAME", field: "name" ,render: (row) => (
              <div
                onClick={() =>
                  history.push({
                    pathname: "/list",
                    state: { row },
                  })
                }
              >
                {console.log('row group details',row)}
                {row.name.en}
              </div>
            )},
            {
              title: "VISIBILITY",
              field: "visibility",
              render: () => (
                <div className="container">
                  <div className="row">
                    <div
                      className="col-12 col-sm-5 col-md-5 col-lg-5"
                      // onClick={() => setselect(!select)}
                    >
                      <Switch
                        {...label}
                        style={{ color: "rgb(17, 24, 39)" }}
                        defaultChecked
                      />
                    </div>
                  </div>
                </div>
              ),
            }, 
            {
                    title: "ACTIONS",
                    field: "actions",
                    render: (row) => (
                      <div className="container" >
                      <div className="row" style={{textAlign: 'center'}}>
                        <div className="col-12 col-sm-5 col-md-5 col-lg-5 mt-1" >
                         <div onClick={() =>handleEdit(row)} className="row cursor-pointer" >
                            <div className="col-12 col-sm-2 col-md-2 col-lg-2">
                            <i
                              style={{ color: "rgb(17, 24, 39)" }}
                              className="fas fa-edit cursor-pointer" 
                            ></i>
                            </div>
                            <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                              <p style={{fontSize:"16px"}}>Edit</p>
                            </div>
                          </div>
                        </div>
                        {/* <div className="col-12 col-sm-5 col-md-5 col-lg-5 mt-1" >
                        <div className="row cursor-pointer">
                            <div className="col-12 col-sm-2 col-md-2 col-lg-2 ">
                            <i
                              style={{ color: "rgb(17, 24, 39)" }}
                              className="fas fa-trash cursor-pointer"
                              onClick={()=>deleteSub(row)}
                            ></i>
                            </div>
                            <div  className="col-12 col-sm-6 col-md-6 col-lg-6">
                              <p style={{fontSize:"16px"}}>Trash</p>
                            </div>
                          </div>
                        </div> */}
                      </div>
                     </div>
                    ),
                },
          ]}
          // key={1}

          data={data}
          // title="Demo Title"
          components={{
            Toolbar: () => null,
          }}
          options={{ 
            search: false,
            showTitle: false,
            headerStyle: {
              width: 15,
              whiteSpace: "nowrap",
              textAlign: "left",
              flexDirection: "row",
              overflow: "hidden",
              textOverflow: "ellipsis",
              paddingLeft: 18,
              paddingRight: 5,
              backgroundColor: "#233044",
              fontWeight: "400",
              color: "white",
              // fontFamily:"Europa",
              fontSize: "14px",
            },
            rowStyle: {
              width: 20,
              textAlign: "left",
              flexDirection: "row",
              paddingLeft: 2,
              paddingRight: 2,
              fontSize: "13px",
              flexWrap: "wrap",
            },
          }}
        />
      </div> 
      <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box style={style}>
        <Typography style={{color:"#233044"}} id="modal-modal-title" variant="h6" component="h4">
          Add New Sub Group
        </Typography>
        <Divider classes={{root: "green"}} my={6} />
         {/* <InputLabel htmlFor="my-input">Name Eng</InputLabel> */}
         {/* <TextField fullWidth id="outlined-basic" label="Name Eng" variant="outlined" />
         <TextField fullWidth style={{marginTop:"10px"}} id="outlined-basic" label="Name German" variant="outlined" /> */}
         <div className="mb-3">
            <label htmlFor="exampleFormControlInput1" className="form-label">
              Name Eng
            </label>
            <input
              onChange={(e) => setnameen(e.target.value)}
              type="email"
              className="form-control"
              id="exampleFormControlInput1"
              placeholder="Name Eng"
            />
          </div>
          <div className="mb-3">
            <label htmlFor="exampleFormControlInput1" className="form-label">
              Name German
            </label>
            <input
              onChange={(e) => setnamege(e.target.value)}
              type="email"
              className="form-control"
              id="exampleFormControlInput1"
              placeholder="Name German"
            />
          </div>
         <Box sx={{ minWidth: 120}} className="mt-3">
          <FormControl fullWidth >
            <InputLabel id="demo-simple-select-label">Choose Gropu for this Sub Group</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selected}
              label="Age"
              onClick={(event)=>handleChangeSelect(event)}
            >
              {
                selectgrp.map((data,i)=>{
                  return (
                    <MenuItem value={data}>{data.name}</MenuItem>
                  )
                })
              }
             
            </Select>
          </FormControl>
    </Box>
          <ButtonGroup style={{marginTop:"60px"}} fullWidth variant="contained" aria-label="outlined primary button group">
            <Button   onClick={()=>createGroup(
                "https://prod.haelf.com/api/groups/addSubGroup"
              )} style={{backgroundColor:"#233044",color:"white"}}>ADD GROUP</Button>
            <Button style={{marginLeft:"5px",color:"#233044",backgroundColor:"white",border:"1px solid #233044"}}>CANCEL</Button>
         </ButtonGroup>
        </Box>
    </Modal>
    </React.Fragment>
  );
}

export default SubGroupDet;


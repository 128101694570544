import {
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Grid,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Switch,
  Button,
  Modal,
  Box,
  Typography,
  FormControl,
  ButtonGroup
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";

import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";
import { get, headers, post, URL } from "../../services";
import calendarStyle from "./Calendar.style";
import { Redirect, useHistory } from "react-router-dom";
import plus from "../dashboards/assets/plus.png";
import { useSnackbar } from "notistack";
const label = { inputProps: { "aria-label": "Switch demo" } };

const FullCalendarWrapper = styled.div`
  ${calendarStyle}
`;
const style = {
  position: 'absolute',
  padding: "40px",
  top: '50%',
  backgroundColor: "white",
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  // bgcolor: 'background.paper',
  border: '1px solid rgb(17,24,39)',
  boxShadow: 24,
  p: 4,
};
const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const data = [
  {
    currency: "EUR",
    country: "German",
  },
  {
    currency: "GDP",
    country: "United Kingdom",
  },
];
function Currencies() {
  const history = useHistory();
  const authUser = useSelector((state) => state.authReducer);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);
  const [loader, setLoader] = useState(false);
  const [currencies, setCurrencies] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [selected, setselected] = React.useState('');
  const [country, setcountry] = React.useState('');
  const [currency, setcurrency] = React.useState('');
  const [currency_id, setcurrency_id] = React.useState('');
  const [selectgrp, setselectgrp] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const handleOpen2 = () => { 
    setOpen2(true)};
  const handleClose2 = () => {
    setOpen2(false)
    setcountry("")
    setcurrency("")
  };
  useEffect(() => {
    getCurrencies();
  }, []);
  const getCurrencies = () => {
    // setCurrencies(data);
    // console.log(authUser,"authUserauthUser")
    var url=URL.getCurrency+`?offset=0&per_page=10&lang=${authUser.lang}`
    get(url).then((res) => {
      console.log(res, "resss")
      setCurrencies(res.data);
    })
      .catch((error) => {
        // alert(error?.message)
      })
  };

  const updateCurrency=()=>{
 var data={
       lang:"en",
       currency_id:currency_id,
       country:country,
       currency:currency
     }
     console.log(URL.deleteCurrency,"url")
     console.log(data,"data")
    
         post(URL.updateCurrency,data).then(res=>{
           console.log(res,"res")
           enqueueSnackbar(res.msg,{
             variant:'success'
             })
             getCurrencies()
         }).catch(error=>{
           console.log(error,"error")
           const message = error?.msg || "Something went wrong";
           enqueueSnackbar(message,{
             variant:'error'
             })
         })
  }
  const handleEdit = (row) => {
    console.log(row,"row")
   setcountry(row.country)
   setcurrency(row.currency)
   setcurrency_id(row._id)
    handleOpen2() 
  };
  const addCurrency = () => {
    var data={
        lang:"en",
       country,
       currency
    }
    console.log(data,"dataa")
    let err=false
    Object.keys(data).map(it=>{
      if(!data[it]){
        enqueueSnackbar(`${it} is required`,{
          variant:'error'
        })
        err =true
        
      return
      }
    })
    console.log('loc',location)

    if(err){
      return
    }
    // console.log()
    // if(userproduct){
    //   data.product_id = userproduct._id;
    // }
    console.log(data)
    post(URL.addCurrency,data)
           .then((response) => {
            console.log("res", response);
            if (response.status === true) {
              // setmsg(response.msg)
              var res= response.data
              // handleClick1({vertical: 'top',horizontal: 'center'})
              enqueueSnackbar(response.msg,{
                variant:'success'
              })
              getCurrencies()
              // history.push({
              //   pathname: "/components/producttestimonials",
              //   state: {  ...row},
              // })
              setOpen(false)
            } else {
              // handleClick1({vertical: 'top',horizontal: 'center'})
              // setmsg(response.msg)
              enqueueSnackbar(response.msg,{
                variant:'success'
              })
            }
          })
          .catch((error) => {
            console.log(error.response);
            // handleClick1({vertical: 'top',horizontal: 'center'})
            // setmsg(error.message)
            const message = error.message || "Something went wrong";
            enqueueSnackbar(message,{
              variant:'error'
            })
            setOpen(false)
          });
  };

  const deleteCurrency = (row) => {
    console.log(row,"row")
   
   var data={
      lang:"en",
      currency_id:row._id
      // currency_id:row._id,
      // country:row.country,
      // currency:row.currency
    }
    console.log(URL.deleteCurrency,"url")
    console.log(data,"data")
   
        post(URL.deleteCurrency,data).then(res=>{
          console.log(res,"res")
          enqueueSnackbar(res.msg,{
            variant:'success'
            })
            getCurrencies()
        }).catch(error=>{
          console.log(error,"error")
          const message = error?.msg || "Something went wrong";
          enqueueSnackbar(message,{
            variant:'error'
            })
        })
  };

  return (
    <React.Fragment>
      <Helmet title="Currencies" />
      <Grid item>
        <Button
          style={{ marginBottom: 20 }}
          variant="outlined"
          onClick={handleOpen}
        >
          {" "}
          <img
            src={plus}
            style={{ width: "20px", height: "20px", marginRight: "4px" }}
          />
          
          ADD NEW CURRENCY
        </Button>
      </Grid>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form >
          <Box style={style}>
            <Typography style={{ color: "#233044" }} id="modal-modal-title" variant="h6" component="h4">
              Add New Currency
            </Typography>
            <Divider classes={{ root: "green" }} my={6} />
            <div className="mb-3">
              <label htmlFor="exampleFormControlInput1" className="form-label">
                Currency
              </label>
              <input
                onChange={(e) => setcurrency(e.target.value)}
                type="text"
                className="form-control"
                id="exampleFormControlInput1"
                placeholder="Currency"
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="exampleFormControlInput1" className="form-label">
                Country
              </label>
              <input
                onChange={(e) => setcountry(e.target.value)}
                type="text"
                className="form-control"
                id="exampleFormControlInput1"
                placeholder="Country"
                required
              />
            </div>
             <ButtonGroup style={{ marginTop: "60px" }} fullWidth variant="contained" aria-label="outlined primary button group">
              <Button onClick={() => addCurrency()} style={{ backgroundColor: "rgb(35, 48, 68)", color: "white" }}>ADD CURRENCY</Button>
              <Button onClick={() => setOpen(false)} style={{ marginLeft: "5px", color: "rgb(35, 48, 68)", backgroundColor: "white", border: "1px solid #233044" }}>CANCEL</Button>
            </ButtonGroup>
          </Box>
        </form>
      </Modal>

      <Modal
          open={open2}
          onClose={handleClose2}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <form>
            <Box style={style}>
              <Typography
                style={{ color: "#233044",textAlign:"center" }}
                id="modal-modal-title"
                variant="h6"
                component="h4"
              >
                Edit Currency/Country
              </Typography>
              <Divider classes={{ root: "green" }} my={6} />
                <div className="mb-3">
                <label htmlFor="exampleFormControlInput1" className="form-label">
                  Currency
                </label>
                <input
                  onChange={(e) => setcurrency(e.target.value)}
                  type="text"
                  value={currency}
                  className="form-control"
                  id="exampleFormControlInput1"
                  placeholder="Client Name"
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="exampleFormControlInput1" className="form-label">
                  Country
                </label>
                <input
                  onChange={(e) => setcountry(e.target.value)}
                  type="text"
                  value={country}
                  className="form-control"
                  id="exampleFormControlInput1"
                  placeholder="Client Title"
                  required
                />
              </div>
              <ButtonGroup
                style={{ marginTop: "60px" }}
                fullWidth
                variant="contained"
                aria-label="outlined primary button group"
              >
                <Button
                  onClick={()=>updateCurrency()}
                  style={{ backgroundColor: "rgb(35, 48, 68)", color: "white" }}
                >
                  Edit Currency/Country
                </Button>
                <Button
                  onClick={() => setOpen2(false)}
                  style={{
                    marginLeft: "5px",
                    color: "rgb(35, 48, 68)",
                    backgroundColor: "white",
                    border: "1px solid #233044",
                  }}
                >
                  CANCEL
                </Button>
              </ButtonGroup>
            </Box>
          </form>
        </Modal>

      {currencies && currencies.length > 0 ? (
        <div style={{ maxWidth: "100%" }}>
          <MaterialTable
            columns={[
              {
                title: "NO",
                field: "tableData.id",
                render: (row, i) => <div>{row.tableData.id + 1}</div>,
              },
              {
                title: "CURRENCY",
                field: "currency",
                render: (row) => <div>{row.currency}</div>,
              },
              {
                title: "COUNTRY",
                field: "country",
                render: (row) => <div>{row.country} </div>,
              },

              {
                title: "VISIBILITY",
                field: "active",
                render: () => (
                  <div className="container">
                    <div className="row">
                      <div className="col-12 col-sm-5 col-md-5 col-lg-5">
                        <Switch
                          {...label}
                          style={{ color: "rgb(17, 24, 39)" }}
                          defaultChecked
                        />
                      </div>
                    </div>
                  </div>
                ),
              },
              {
                title: "EDIT",
                field: "actions",
                render: (row) => (
                  <div className="container">
                    <div className="row">
                      <div className="col-12 col-sm-5 col-md-5 col-lg-5 mt-1">
                        {/* <Edit2 style={{cursor:'pointer'}} onClick={() =>
                          history.push({
                              pathname: "/components/productdetails",
                              state: { ...row ,action:"edit"},
                            })
                          }
                        /> */}
                        <i
                          className="fas fa-pen"
                          onClick={() => handleEdit(row)}
                        ></i>
                      </div>
                    </div>
                  </div>
                ),
              },
              {
                title: "DELETE",
                field: "actions",
                render: (row) => (
                  <div className="container">
                    <div className="row">
                      <div className="col-12 col-sm-5 col-md-5 col-lg-5 mt-1">
                        <i
                          style={{ color: "rgb(17, 24, 39)" }}
                          className="fas fa-trash cursor-pointer"
                          onClick={() => deleteCurrency(row)}
                        ></i>
                      </div>
                    </div>
                  </div>
                ),
              },
            ]}
            data={currencies}
            // title="Demo Title"
            components={{
              Toolbar: () => null,
            }}
            options={{
              pageSizeOptions: [currencies.length, 5, 15, 30],
              pageSize: currencies.length, // make initial page size
              tableLayout: "fixed",
              // pageSize: userdata.length, // make initial page size
              actionsColumnIndex: -1,
              search: false,
              showTitle: false,
              headerStyle: {
                width: 15,
                whiteSpace: "nowrap",
                textAlign: "left",
                flexDirection: "row",
                overflow: "hidden",
                textOverflow: "ellipsis",
                paddingLeft: 18,
                paddingRight: 5,
                backgroundColor: "rgb(17, 24, 39)",
                fontWeight: "400",
                color: "white",
                fontSize: "14px",
              },
              rowStyle: {
                width: 50,
                textAlign: "left",
                flexDirection: "row",
                paddingLeft: 2,
                paddingRight: 2,
                fontSize: "13px",
                flexWrap: "wrap",
              },
            }}
          />
        </div>
      ) : (
        <p>no data</p>
      )}
    </React.Fragment>
  );
}

export default Currencies;

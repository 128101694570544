import { Box, Button, FormControl, Grid,Divider as MuiDivider,ButtonGroup, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Modal,Typography} from '@material-ui/core'
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import styled, { withTheme } from 'styled-components/macro';
import { Circle } from 'react-feather'
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { store } from '../../redux/store';
import { useSnackbar } from 'notistack';
import {URL ,post, get} from '../../services';
import { spacing } from "@material-ui/system"; 
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
  } from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
import { FormatStrikethrough } from '@material-ui/icons';

const Wrapper = styled.div`
    width:100%;
    height:28rem;
    border:1px solid;

`;

const Header = styled.div`
    width:100%;
    display:flex;
    align-items:center;
    justify-content:space-between;
    padding-left:10px;
    padding-right:10px;
    border-bottom:1px solid;
    height:2rem;
    background:#233044;
    & > p{
        margin: initial;
        color:white;
    }
`;

const CustomBox = (props) => {
    return (
        <Wrapper style={props.style}>
            <Header>
                <p>{props.title}</p>
                {/* <p className='cursor-pointer'>Edit</p> */}
            </Header>
            {props.children}
        </Wrapper>
    )
}

function OrderInfo({ data,formik }) {
    return (
        <CustomBox title='ORDER INFO'>
            <Box style={{ padding: '8px' }}>
                <Grid>
                    <label>Date Created</label>
                    <Grid container justify='space-between'>
                        <Grid item xs={5}>
                            <TextField value={formik?.values?.date} variant='outlined'
                                id="date"
                                name="date"
                                onChange={formik.handleChange}
                                error={formik.errors.date && formik.touched.date}
                                disabled
                                />
                        </Grid>
                        <Grid item xs={1} style={{ display: 'flex', alignItems: 'center' }}>
                            <Circle />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField value={formik?.values.hour} variant='outlined' 
                             id="hour"
                             name="hour"
                             onChange={formik.handleChange}
                             error={formik.errors.hour && formik.touched.hour}
                             disabled
                            />
                        </Grid>
                        <Grid item xs={1} style={{ display: 'flex', alignItems: 'center' }}>
                            :
                        </Grid>
                        <Grid item xs={2}>
                            <TextField value={formik?.values?.minute} variant='outlined' 
                            id="minute"
                            name="minute"
                            onChange={formik.handleChange}
                            error={formik.errors.minute && formik.touched.minute}
                            disabled
                             />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid>
                    <label>Status</label> 
                    <FormControl fullWidth>
                        <Select
                            id="status"
                            name="status"
                            onChange={formik.handleChange}
                            error={formik.errors.status && formik.touched.status}
                            label="status"
                            fullWidth
                            value={formik?.values?.status ? formik?.values?.status : ""}
                        >{console.log(data?.status)}
                            <MenuItem value={'completed'}>Completed</MenuItem>
                            <MenuItem value={'onHold'}>OnHold</MenuItem>
                            <MenuItem value={'In Process'}>In progress</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid>
                    <label>Customer</label>
                    {console.log("Customer",formik.values)}
                    <TextField
                    value={formik.values.customer} 
                    fullWidth variant='outlined' 
                     id="customer"
                     name="customer"
                     onChange={formik.handleChange}
                     error={formik.errors.customer && formik.touched.customer}
                     disabled
                     />
                </Grid>
                <Grid container style={{ borderBottom: '1px solid', marginTop: 12 }}>
                    <Grid item xs={6}>
                        <p>Email Address</p>
                    </Grid>
                    <Grid itemxs={6}>
                        <p style={{ textAlign: 'right' }}>{formik?.values?.email}</p>
                    </Grid>
                </Grid>
                <Grid container style={{ borderBottom: '1px solid', marginTop: 12 }}>
                    <Grid item xs={6}>
                        <p>Phone Number</p>
                    </Grid>
                    <Grid itemxs={6}>
                        <p style={{ textAlign: 'right' }}>{formik?.values?.phNum}</p>
                    </Grid>
                </Grid>
                <Grid style={{ marginTop: 12 }}>
                    <label>Payment Method</label>
                    {console.log("Customer Payment Method", formik.values.paymentMethod)}
                    <FormControl fullWidth>
                        <Select
                            id="paymentMethod"
                            label="paymentMethod"
                            onChange={formik.handleChange}
                            error={formik.errors.paymentMethod && formik.touched.paymentMethod}
                            fullWidth 
                            value={formik?.values?.paymentMethod ? formik?.values?.paymentMethod : ""}
                            disabled
                        >
                            <MenuItem value={"one_time"}>One time purchase</MenuItem>
                            <MenuItem value={"subscription"}>subscribe</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Box>
        </CustomBox>
    )
}
function ShippingInfo({ data,formik }) {
    return (
        <CustomBox title='SHIPPING INFO'>
            <Box style={{ padding: '8px' }}>
                {/* <Grid>
                    <Grid container justify='space-between'>
                        <Grid item xs={5}>
                            <label>First Legal Name</label>
                        </Grid>
                        <Grid item xs={5}>
                            <label>Last Legal Name</label>
                       </Grid>
                    </Grid>
                </Grid> */}
                <Grid>
                    <label>Street Number</label>
                    <TextField value={formik?.values?.streetNum} fullWidth variant='outlined' 
                    id="streetNum"
                    name="streetNum"
                    onChange={formik.handleChange}
                    error={formik.errors.streetNum && formik.touched.streetNum}
                     />
                </Grid>

                <Grid container justify='space-between'>
                    <Grid item xs={5}>
                        <label>Door Number</label>
                        <TextField value={formik?.values?.doorNum} fullWidth variant='outlined' 
                         id="doorNum"
                         name="doorNum"
                         onChange={formik.handleChange}
                         error={formik.errors.doorNum && formik.touched.doorNum}
                     />
                    </Grid>
                    <Grid item xs={5}>
                        <label>Stair Number</label>
                        <TextField value={formik?.values?.stairNum} fullWidth variant='outlined' 
                        id="stairNum"
                        name="stairNum"
                        onChange={formik.handleChange}
                        error={formik.errors.stairNum && formik.touched.stairNum}
                     />
                    </Grid>
                </Grid>

                <Grid container justify='space-between'>
                    <Grid item xs={5}>
                        <label>City</label>
                        <TextField value={formik?.values?.city} fullWidth variant='outlined' 
                        id="city"
                        name="city"
                        onChange={formik.handleChange}
                        error={formik.errors.city && formik.touched.city}
                     />
                    </Grid>
                    <Grid item xs={5}>
                        <label>Country</label>
                        <TextField value={formik?.values?.country} fullWidth variant='outlined' 
                         id="country"
                         name="country"
                         onChange={formik.handleChange}
                         error={formik.errors.country && formik.touched.country}
                     />
                    </Grid>
                </Grid>

                <Grid>
                    <label>Phone Number</label>
                    <TextField value={formik?.values?.shippingPhNum} fullWidth variant='outlined' 
                    id="shippingPhNum"
                    name="shippingPhNum"
                    onChange={formik.handleChange}
                    error={formik.errors.shippingPhNum && formik.touched.shippingPhNum}
                     />
                </Grid>
                <Grid>
                    <label>Vouched %</label>
                    <TextField value={data.info_check} fullWidth variant='outlined' 
                    id="vouchedNum"
                    name="vouchedNum"
                     />
                </Grid>
            </Box>
        </CustomBox>
    )
}

function Items({ data }) {
    const lang = useSelector(state => state.authReducer.lang)
    const { enqueueSnackbar } = useSnackbar();
    const [totals, settotals] = useState({
        item_total: 0,
        shipping: 0,
        order_total: 0
    })
    useEffect(() => {
        console.log(`data---${data.order_numb}`, data)
        let item_total = 0
        let shipping = 0
        let order_total = 0
        data?.products.map(item => {
            item_total += parseFloat(item?.price?.uk ? item?.price?.uk : item?.price ? item?.price : '')
        })
        order_total = parseFloat(shipping + item_total).toFixed(2)
        {console.log('data--- total', data?.products)}
        settotals({
            item_total, shipping, order_total
        })
    }, [])

    const RefundCharge = async ()=>{
        let dataToSend={
            order_id:data._id
        }
        await post(URL.refundOrder,dataToSend).then(res=>{
            if(res.status){
                enqueueSnackbar(res.msg,{
                    variant:'success'
                })
            }
        }).catch(err=>{
            console.log(err)
            let msg = err?.msg || 'Something wnt wrong '
            enqueueSnackbar(msg,{
                variant:'error'
              })
        })
        console.log('data',data)
    }

    return (
        <Box style={{ border: '1px solid', marginTop: 10 }}>
            <Grid container>
                <TableContainer >
                    <Table aria-label="spanning table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">Item</TableCell>
                                <TableCell align="center">Questionnaire</TableCell>
                                <TableCell align="center">Prescription</TableCell>
                                <TableCell align="center">Cost</TableCell>
                                <TableCell align="center">Quantity </TableCell>
                                <TableCell align="center">Total</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                data?.products?.length && data?.products.map(item => {
                                    return <TableRow>
                                        <TableCell align="center">{item?.name[lang] ? item?.name[lang] : item.name ? item.name :''}</TableCell>
                                        <TableCell align="center"></TableCell>
                                        <TableCell align="center"></TableCell>
                                        <TableCell align="center">{item?.price?.uk ? item?.price?.uk : item?.price ? item?.price : ''}</TableCell>
                                        <TableCell align="center">{item?.quantity}</TableCell>
                                        <TableCell align="center">{parseFloat((item?.price?.uk ? item?.price?.uk : item?.price ? item?.price : '') * item?.quantity).toFixed(2)}</TableCell>
                                    </TableRow>
                                })
                            }
                            <TableRow>
                                <TableCell rowSpan={4} />
                                <TableCell rowSpan={4} />
                                <TableCell colSpan={3}>item Subtotal</TableCell>
                                {console.log('data--- total', totals)}
                                <TableCell align="right">{data.gross_amount}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan={3}>Shipping</TableCell>
                                <TableCell align="right">{totals.shipping}</TableCell>
                                {/* <TableCell align="right"></TableCell> */}
                            </TableRow>
                            {data?.coupon_percent !== 0 && (
                            <TableRow>
                                <TableCell colSpan={3}>Discount</TableCell>
                                <TableCell align="right">{data?.coupon_percent}%</TableCell>
                            </TableRow>
                            )}
                            <TableRow>
                                <TableCell colSpan={3}>Order Total</TableCell>
                                <TableCell align="right">{data.total}</TableCell> 
                            </TableRow>
                        </TableBody>
                        <TableBody>
                            <TableRow>
                                <TableCell onClick={RefundCharge} rowSpan={4} colSpan={2} align="left">
                                    <Button variant='contained'>REFUND</Button>
                                </TableCell>
                                <TableCell rowSpan={4}>Paid</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Box>
    )
}

function UserOrderDetails({ theme }) {
    const { enqueueSnackbar } = useSnackbar();
    const location = useLocation().state;
    const [userAnswers, setuserAnswers] = useState([])
    const [fData, setFData] = useState({});

    // For Modal
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const Divider = styled(MuiDivider)(spacing);
    const lang = useSelector(state=>state.authReducer.lang)
    // Date 
    const [selectedDate, setDate] = useState(moment());
    const [inputValue, setInputValue] = useState(moment().format("DD-MM-YYYY"));

    const onDateChange = (date, value) => {
        setDate(date);
        setInputValue(value);
    };

    const dateFormatter = str => {
        return str;
    };

    useEffect(() => {
        console.log("locc",location)
        if(location._id){
            getAnswer()
            getUserOrderByID();
        }
    }, [])
    const ModalStyle = {
        position: 'absolute',
        padding: "40px",
        top: '50%',
        backgroundColor: "white",
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 700,
        // bgcolor: 'background.paper',
        border: '1px solid rgb(17,24,39)',
        boxShadow: 24,
        p: 4,
    };
    const getAnswer = () =>{
        const dataToSend ={
            lnag:'en',
            order_id:location._id
        }
       get(URL.userQuestionnaire,dataToSend).then(res=>{
           console.log({res})
           setuserAnswers(res.data)
       }).catch(err=>{
           console.log({err})
       })
    }
    // console.log('idd',location._id) 

    const getUserOrderByID = async () => {  
        const dataToSend = {
          lang: 'en',
          order_id: location._id,
        }
        console.log('data to send', dataToSend) 
        let AddOrderInitialVals = {};
        AddOrderInitialVals = await get(URL.getUserOrderByID,dataToSend).then((res)=> {
        console.log('customer fdata res ', res.data); 
          return {
            order_id:res?.data?._id,
            date: moment(res?.data?.createdAt).format('YYYY-MM-DD') ? moment(res?.data?.createdAt).format('YYYY-MM-DD') : "",
            hour: moment(res?.data?.createdAt).format('HH') ? moment(res?.data?.createdAt).format('HH') : "",
            minute: moment(res?.data?.createdAt).format('mm') ? moment(res?.data?.createdAt).format('mm') : "",
            status: res?.data?.status ? res?.data?.status : "",
            customer: `${res?.data?.user_id?.first_name} ${res?.data?.user_id?.last_name}` ? `${res?.data?.user_id?.first_name} ${res?.data?.user_id?.last_name}` : "",
            email: res?.data?.user_id.email ? res?.data?.user_id.email : "",
            phNum: res?.data?.shipping_details?.phone_numb ? res?.data?.shipping_details?.phone_numb : "",
            paymentMethod: res?.data?.payment_type ? res?.data?.payment_type : "",
            streetNum: res?.data?.shipping_details?.street_number ? res?.data?.shipping_details?.street_number : "",
            doorNum: res?.data?.shipping_details?.door_number ? res?.data?.shipping_details?.door_number : "",
            stairNum: res?.data?.shipping_details?.stair_number ? res?.data?.shipping_details?.stair_number : "",
            city: res?.data?.shipping_details?.city ? res?.data?.shipping_details?.city : "",
            country: res?.data?.shipping_details?.country ? res?.data?.shipping_details?.country : "",
            shippingPhNum: res?.data?.shipping_details?.phone_numb ? res?.data?.shipping_details?.phone_numb : "",
            lang: "en"
        };
        })
        .catch((e) => console.log('Error', e)) 

        console.log('fdata obj',AddOrderInitialVals)
        setFData(AddOrderInitialVals)
      }
      console.log('fdata state',fData)
      useEffect(()=> {
        
      },[])
     
    const handleData = async () => {
        console.log('Modal',fData) 
        let data = location;
        let disc = ((data?.gross_amount * data?.coupon_percent)/100).toFixed(2)
        console.log("dts dis", disc)
        let dataToSend = { 
            contact: {
                id: data?._id,
                objectName: data?.shipping_details?.phone_numb
            },
            invoiceDate: data?.createdAt,
            discount: disc > 0 ? disc : 0,
            delieveryDate : data?.createdAt,
            status: data?.status,
            smallSettlement: disc>0 ? true : false,
            contactPerson: {
                id: data?._id,
                objectName: `${data?.user_id?.first_name} ${data?.user_id?.last_name}`
            },
            taxRate: 0.00,
            taxText:'Umsatzsteuer 19%',
            taxType: 'eu',
            invoiceType: 'RE',
            currency:  data?.currency,
            mapAll: true
        }
        
        console.log('dts',dataToSend)
        console.log('dts data--',data)
    }

    // const AddOrderInitialVals = {
    //     order_id:location?._id,
    //     date: moment(location?.createdAt).format('YYYY-MM-DD') ? moment(location?.createdAt).format('YYYY-MM-DD') : "",
    //     hour: moment(location?.createdAt).format('HH') ? moment(location?.createdAt).format('HH') : "",
    //     minute: moment(location?.createdAt).format('mm') ? moment(location?.createdAt).format('mm') : "",
    //     status: location?.status ? location?.status : "",
    //     customer: `${location?.user_id?.first_name} ${location?.user_id?.last_name}` ? `${location?.user_id?.first_name} ${location?.user_id?.last_name}` : "",
    //     email: location?.user_id.email ? location?.user_id.email : "",
    //     phNum: location?.user_id?.phone ? location?.user_id?.phone : "",
    //     paymentMethod: location?.payment_type ? location?.payment_type : "",
    //     streetNum: location?.shipping_details?.street_number ? location?.shipping_details?.street_number : "",
    //     doorNum: location?.shipping_details?.door_number ? location?.shipping_details?.door_number : "",
    //     stairNum: location?.shipping_details?.stair_number ? location?.shipping_details?.stair_number : "",
    //     city: location?.shipping_details?.city ? location?.shipping_details?.city : "",
    //     country: location?.shipping_details?.country ? location?.shipping_details?.country : "",
    //     shippingPhNum: location?.shipping_details?.phone_numb ? location?.shipping_details?.phone_numb : "",
    //     lang: "en"
    // };
    const AddOrderValidationSchema = Yup.object().shape({
        order_id: Yup.string().required('Required'),
        date: Yup.string().required('Required'),
        hour: Yup.string().required('Required'),
        minute: Yup.string().required('Required'),
        status: Yup.string().required('Required'),
        customer: Yup.string().required('Required'),
        email: Yup.string(),
        phNum: Yup.string(),
        paymentMethod: Yup.string().required('Required'),
        streetNum: Yup.string().required('Required'),
        doorNum: Yup.string().required('Required'),
        stairNum: Yup.string().required('Required'),
        city: Yup.string().required('Required'),
        country: Yup.string().required('Required'),
        shippingPhNum: Yup.string().required('Required')
    });
    const handleSubmit = async (dataToSend) => {
       try{
        //     let dataToSend = {
        //     order_id: location?._id, 
        //     status: values.status,
        //     streetNum: values.streetNum,
        //     doorNum: values.doorNum,
        //     stairNum: values.stairNum,
        //     city: values.city,
        //     country: values.country,
        //     shippingPhNum: values.shippingPhNum,
        //     lang: store.getState().authReducer.lang
        // }
        console.log(dataToSend, "dataToSend1")
        dataToSend.status = formik.values.status;
        post(URL.updateOrder, dataToSend)
            .then((response)=>{
                console.log(response,"response")
                enqueueSnackbar(response.msg,{
                  variant:'success'
                })
            }).catch((error) =>{
                console.log("Login error", error);
                const message = "Something Went Wrong"
                enqueueSnackbar(message,{
                    variant:'error'
                })
            });
           }catch(err) {
               console.log('err122', err)
           }
    };
    const formik = useFormik({
        initialValues: fData,
        enableReinitialize:true,
        validationSchema: AddOrderValidationSchema,
        onSubmit: (values) => {
            console.log(values, 'getting values from order')
            let dataToSend = {
                order_id: location?._id, 
                status: location?.status,
                streetNum: values.streetNum,
                doorNum: values.doorNum,
                stairNum: values.stairNum,
                city: values.city,
                country: values.country,
                shippingPhNum: values.shippingPhNum,
                lang: store.getState().authReducer.lang
            }
            handleSubmit(dataToSend)
        },
    });
    console.log(formik.errors)

    const sendToDoctor = ()=>{
        if(userAnswers[0]?.user_answers && userAnswers[0]?.user_answers.length){
            let dataToSend = {
                order_id: location?._id, 
                status: location?.status,
                streetNum: location?.shipping_details?.street_number,
                doorNum: location?.shipping_details?.door_number ,
                stairNum: location?.shipping_details?.stair_number,
                city: location?.shipping_details?.city,
                country: location?.shipping_details?.country,
                shippingPhNum: location?.shipping_details?.phone_numb,
                needs_doctor_approval:true,
                lang: store.getState().authReducer.lang
            }
            handleSubmit(dataToSend)
        }else{
            enqueueSnackbar('This order does not contain any questions',{
                variant:'error'
            })
        }
    }

    const SendToGerman = async ()=>{
        console.log('locationss',location)
        console.log('locationss',formik.values)
        let values = formik.values
        // return
        if(location?.products?.length){
            let ids = location.products.map(prod => prod._id)
        // console.log(ids);
        // if(ids.length){
        //     await post(URL.getProductByIds,{ids,lang}).then(res=>{
                let temp=[]
                location.products.map(prod=>{
                    if(prod){
                        let data =  {
                            "oi_pcode": prod._id,
                            "oi_quantity": prod?.quantity
                        }
                        temp.push(data)
                    }
                })
                let dataToSend={
                    "person": {
                        "telephone": location.shipping_details.phone_numb,
                        "telephone_mobile": location.shipping_details.phone_numb,
                    },
                    "order": {
                        "dynx__o_personid_external": location.user_id._id,
                        "o_orderid_external": location._id,
                        "invoice_firstname": location.shipping_details.first_name,
                        "invoice_lastname": location.shipping_details.last_name,
                        "invoice_street": values.streetNum,
                        "invoice_housenr": `stair-num:${values.stairNum}, door-number:${values.doorNum}`,
                        "invoice_zip": location.shipping_details.zip_code ? location.shipping_details.zip_code : 'null',
                        "invoice_town": values.city,
                        "invoice_country": values.country,
                        "invoice_email": values.email,
                        "deliver_firstname": location.shipping_details.first_name,
                        "deliver_lastname": location.shipping_details.last_name,
                        "deliver_street": location.shipping_details.street_number,
                        "deliver_housenr": `stair-num:${values.stairNum}, door-number:${values.doorNum}`,
                        "deliver_zip": location.shipping_details.zip_code ? location.shipping_details.zip_code : 'null' ,
                        "deliver_town": values.city,
                        "deliver_country": values.country,
                        "orderitems": temp,
                        // "dynx__customer_prescription_remote": "https:\/\/my.prescription-cloud.de\/printed\/de_DE_Mustermann_Max_prescription_12345678-1.pdf"
                    }
                }
                let sendData = {            
                    germanData:dataToSend,
                    productsIds:ids,
                    order_id:location._id,
                    lang
                }
                console.log(dataToSend);
            await post(URL.sendToGerman,sendData).then(res => {
                console.log(res);
                enqueueSnackbar( res.msg ,{
                    variant:'success'
                })
            }).catch(err=>{
                console.log(err);
                let msg = err.msg || 'Something went wrong'
                enqueueSnackbar( msg ,{
                    variant:'error'
                })
            })
        }
    }

    const SendToBritish = () =>{
        let temp = {...location}
        temp.shipping_details = {...location.shipping_details,...formik.values}
        console.log(temp);
        post(URL.sendToBritish,{order:temp}).then(res=>{
            console.log('response',res);
            if(res.status){
                enqueueSnackbar(res.msg,{
                    variant:'success'
                })
            }else{
                enqueueSnackbar(res.msg,{
                    variant:'error'
                })
            }
        }).catch(err=>{
            let msg = err.msg || 'Something went wrong'
            enqueueSnackbar(msg,{
                variant:'error'
            })
        })
    }


    return (
        <Box key={fData}>
            <Grid container>
                <Grid style={{ borderRight: '1px solid' }} item sm={9}>
                    <Grid container justify='space-around' spacing={2}>
                        <Grid item xs={12} sm={6}>
                           {formik.values?.order_id && <OrderInfo formik={formik} data={location} />}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                           {formik.values?.order_id && <ShippingInfo formik={formik} data={location} />}
                        </Grid>
                    </Grid>
                    <Grid>
                        <Items data={location} />
                    </Grid>
                </Grid>
                <Grid style={{ borderLeft: '1px solid', display: 'flex', justifyContent: 'center' }} item sm={3}>
                    <Box style={{ width: '95%' }}>
                        <CustomBox style={{ height: '22rem' }} title='ACTIONES'>
                            <Box style={{ padding: 10, width: '100%' }}>
                                <Grid container>
                                    <Button style={{ marginBottom: 15, width: '100%' }} onClick={SendToBritish} fullWidth variant='outlined'>SEND ORDER TO BRITISH PHARMACY</Button>
                                    <Button style={{ marginBottom: 15, width: '100%' }} fullWidth variant='outlined' onClick={SendToGerman}>SEND ORDER TO GERMAN PHARMACY</Button>
                                    <Button style={{ marginBottom: 15, width: '100%' }} onClick={sendToDoctor} fullWidth variant='outlined'>SEND ORDER TO DOCTOR</Button>
                                    <Button style={{ marginBottom: 15, width: '100%' }} onClick={() => handleData()} fullWidth variant='outlined'>SEND INVOICE</Button>
                                    <Button style={{ marginBottom: 15, width: '100%' }} fullWidth variant='outlined'>SEND CONFIRMATION EMAIL</Button>
                                    <Button style={{ marginBottom: 15, width: '100%' }} fullWidth variant='outlined' onClick={formik.handleSubmit}>UPDATE ORDER INFO</Button>
                                </Grid>

                                {/* Modal */}

                                {/* <Modal
                                    open={open}
                                    onClose={handleClose}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description"
                                >
                                    <form >
                                    <Box style={ModalStyle}>
                                        <Typography style={{ color: "#233044" }} id="modal-modal-title" variant="h6" component="h4">
                                            Enter Delivery Date
                                        </Typography>
                                        <Divider classes={{ root: "green" }} my={6} />
                                        <div className="mb-3">
                                        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                            <KeyboardDatePicker
                                            autoOk={true}
                                            showTodayButton={true}
                                            value={selectedDate}
                                            format="DD-MM-YYYY"
                                            inputValue={inputValue}
                                            onChange={onDateChange}
                                            rifmFormatter={dateFormatter}
                                            />
                                        </MuiPickersUtilsProvider>
                                        </div>
                                        <ButtonGroup style={{ marginTop: "60px" }} fullWidth variant="contained" aria-label="outlined primary button group">
                                        <Button onClick={() => handleData()} style={{ backgroundColor: "rgb(35, 48, 68)", color: "white" }}>ADD </Button>
                                        <Button onClick={() => setOpen(false)} style={{ marginLeft: "5px", color: "rgb(35, 48, 68)", backgroundColor: "white", border: "1px solid #233044" }}>CANCEL</Button>
                                        </ButtonGroup>
                                    </Box>
                                    </form>
                                </Modal> */}
                            </Box>
                        </CustomBox>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

export default withTheme(UserOrderDetails)

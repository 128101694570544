import { Button, CircularProgress, Divider, Grid, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { post, URL } from '../../services';

function BulkUpload() {
    const [loading,setloading] = useState(false)
    const [csv,setcsv] = useState({})

    const uploadFile = async () =>{
        setloading(true)
        if(csv){
            var data = new FormData();
            data.append('csv',csv)
            await post(URL.bulkUpload,data).then(res=>{
                console.log(res);
                setloading(false)
                alert('uploaded succesfully')
            }).catch(err=>{
                setloading(false)
                console.log('error',err);
            })
        }
    }

  return (
        <React.Fragment>
            <Helmet title="Products" /> 
            <Grid justify="space-between" container spacing={6}>
                <Grid item>
                    <Typography style={{ color: "#233044" }} variant="h3" gutterBottom>
                        Products Upload
                    </Typography>
                </Grid> 
            </Grid>
            <Divider/>
            <Grid container style={{marginTop:'2rem'}}>
                <Grid item xs={12} >
                    <Typography style={{ color: "#233044" }} variant="h4" gutterBottom>
                        Upload your product csv
                    </Typography>
                    <input
                        accept=".csv"
                        style={{display:'none'}}
                        id="contained-button-file"
                        onChange={(e)=>setcsv(e.target.files[0])}
                        type="file"
                    />
                    <label htmlFor="contained-button-file">
                        <Button variant='outlined' component="span" style={{marginTop:'1rem'}}>Upload Prodcuts CSV</Button>
                    </label>
                    
                  
                </Grid>
                <Grid item xs={12} style={{display:'flex',justifyContent:'center'}}>
                    {!loading ?  <Button onClick={uploadFile} variant='outlined' component="span" style={{marginTop:'1rem'}}>Proceed</Button> : <CircularProgress/>}
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

export default BulkUpload;

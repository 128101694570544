import {
    Breadcrumbs as MuiBreadcrumbs,
    Card as MuiCard,
    Grid,
    CardContent as MuiCardContent,
    Divider as MuiDivider, Switch,
    Button,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";

import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";
import { get, post, URL } from '../../services';
import calendarStyle from "./Calendar.style";
import { Redirect, useHistory } from "react-router-dom";
import plus from "../dashboards/assets/plus.png";
import { useSnackbar } from "notistack";
const label = { inputProps: { "aria-label": "Switch demo" } };


const FullCalendarWrapper = styled.div`
  ${calendarStyle}
`;

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);


function Coupons() {
    const history = useHistory();
    const authUser = useSelector(state => state.authReducer)

    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(100);
    const [loader, setLoader] = useState(false);
    const [coupons, setCoupons] = useState([]);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        getCoupons()
    },[])
    const getCoupons=()=>{
        setLoader(false)
        var url=`${URL.getCoupons}?offset=${offset}&per_page=${limit}&lang=${authUser.lang}`
        // get(URL.getCoupons+url).then((response) => {
        get(url).then((response) => {
            setLoader(false)
            if(response.status){
                setCoupons(response.data)
            }
            console.log("get copupons",response)
        }).catch(err=>{
            console.log(err)
            setLoader(false)

        })



    }
    const handleEdit = (row) =>{
        let rowData ={
            row
        }

        history.push({
          pathname: "/coupons/details",
          state: rowData,
        })
      }
      const addCoupon = () =>{


        history.push({
          pathname: "/coupons/details",
          state: null,
        })
      }
   
      const deleteFaq = (row) =>{

        console.log(row)
        let Data = {
          coupon_id:row._id
        }
        post(URL.deleteCoupon,Data).then(res=>{
          enqueueSnackbar(res.msg,{
            variant:'success'
            })
            getCoupons()
        }).catch(error=>{
          console.log(error)
          const message = error?.msg || "Something went wrong";
          enqueueSnackbar(message,{
            variant:'error'
            })
        })
      }

  return (
    <React.Fragment>
      <Helmet title="Coupons" />
      <Grid item>
      <Button style={{marginBottom:20}} variant="outlined" onClick={addCoupon}>
            {" "}
            <img
              src={plus}
              style={{ width: "20px", height: "20px", marginRight: "4px" }}
            />
            ADD COUPON
          </Button>
          </Grid>
      {
          coupons.length > 0 ?
          <div style={{ maxWidth: "100%" }}>
          <MaterialTable
            columns={[
                {
                    title: "NO",
                    field: "tableData.id",
                    render: (row,i) => (
                            <div>
                              {row.tableData.id+1}

                          </div>
                        )   ,
                  },
              {
                title: "COUPON NAME",
                field: "coupon_name",
                render: (row) => (
                  <div>
                      {row.coupon_name}

                  </div>
                ),
              },
              {
                title: "COUPON_CODE",
                field: "code",
                render: (row) => (
                  <div  >{row.code} </div>
                ),
              },
              {
                title: "DISCOUNT %",
                field: "discount_percent",

                render: (row) => (
                  <div>{row.discount_percent} %</div>
                ),
              },
              {
                title: "VISIBILITY",
                field: "active",
                render: () => (
                  <div className="container">
                    <div className="row">
                      <div className="col-12 col-sm-5 col-md-5 col-lg-5" >
                        <Switch
                          {...label}
                          style={{ color: "rgb(17, 24, 39)" }}
                          defaultChecked
                        />
                      </div>
                    </div>
                  </div>
                ),
              },
              {
                title: "EDIT",
                field: "actions",
                render: (row) => (
                  <div className="container">
                    <div className="row">
                      <div className="col-12 col-sm-5 col-md-5 col-lg-5 mt-1">
                        {/* <Edit2 style={{cursor:'pointer'}} onClick={() =>
                          history.push({
                              pathname: "/components/productdetails",
                              state: { ...row ,action:"edit"},
                            })
                          }
                        /> */}
                          <i className="fas fa-pen" onClick={() => handleEdit(row)}
                      ></i>
                      </div>
                    </div>
                  </div>
                ),
              },
              {
                title: "DELETE",
                field: "actions",
                render: (row) => (
                  <div className="container">
                    <div className="row">
                      <div className="col-12 col-sm-5 col-md-5 col-lg-5 mt-1">
                        <i
                          style={{ color: "rgb(17, 24, 39)" }}
                          className="fas fa-trash cursor-pointer"
                          onClick={()=>deleteFaq(row)}
                        ></i>
                      </div>
                    </div>
                  </div>
                ),
              },
            ]}
            data={coupons}
            // title="Demo Title"
            components={{
              Toolbar: () => null,
            }}
            options={{
              pageSizeOptions: [coupons.length, 5, 15, 30],
              pageSize: coupons.length, // make initial page size
              tableLayout: "fixed",
              // pageSize: userdata.length, // make initial page size
              actionsColumnIndex: -1,
              search: false,
              showTitle: false,
              headerStyle: {
                width: 15,
                whiteSpace: "nowrap",
                textAlign: "left",
                flexDirection: "row",
                overflow: "hidden",
                textOverflow: "ellipsis",
                paddingLeft: 18,
                paddingRight: 5,
                backgroundColor: "rgb(17, 24, 39)",
                fontWeight: "400",
                color: "white",
                // fontFamily:"Europa",
                fontSize: "14px",
              },
              rowStyle: {
                width: 50,
                textAlign: "left",
                flexDirection: "row",
                paddingLeft: 2,
                paddingRight: 2,
                fontSize: "13px",
                flexWrap: "wrap",
              },
            }}
          />

        </div>
        : <p>no data</p>
      }
    </React.Fragment>
  );
}

export default Coupons;

import { Button, CircularProgress, Divider, Grid, TextField, Typography } from '@material-ui/core';
import { DropzoneDialog } from 'material-ui-dropzone';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { post, URL } from '../../services';

function BulkImages() {
    const [loading,setloading] = useState(false)
    const [multipleImg, setmultipleImg] = useState(false)
    const [slug,setSlug] = useState('')
    const [images,setImages] = useState([])
    const lang = useSelector(state=> state.authReducer.lang)
    const { enqueueSnackbar } = useSnackbar();

    const handleMultipleUpload = async () =>{
      console.log(images);
      console.log(slug);
      if(!slug || images.length == 0){
        enqueueSnackbar('slug and images are required',{
          variant:'error'
        })
        return
      }
      // return
      var formData = new FormData();
      formData.append('path', `pathOfs3/products/${slug}`);
      images.map(file=> formData.append('files', file))

      await post(URL.UploadMultiple,formData).then(res=>{
        console.log(res);
        if(res.status){
          console.log('upload response',res.data)
          uploadData(res.data)
        }
      }).catch(err=>{
        let msg = err.msg || 'Something went wrong' 
        // console.log('upload response error',msg)
        enqueueSnackbar(msg,{
          variant:'error'
        })
      })
    } 
  
  const uploadData = async (links) =>{
    if(links.length){
      await post(URL.bulkImages,{links,slug,lang:lang}).then(res =>{
        if(res.status){
          enqueueSnackbar('Successfully Uploaded',{
            variant:'success'
          })
        }
      }).catch((err)=>{
        let msg = err.msg || 'Something went wrong' 
        // console.log('upload response error',msg)
        enqueueSnackbar(msg,{
          variant:'error'
        })
      })
    }
  }

  return (
        <React.Fragment>
            <Helmet title="Products" /> 
            <Grid justify="space-between" container spacing={6}>
                <Grid item>
                    <Typography style={{ color: "#233044" }} variant="h3" gutterBottom>
                        Products Images Upload
                    </Typography>
                </Grid> 
            </Grid>
            <Divider/>
            <Grid container justify='center' style={{marginTop:'2rem'}}>
                <Grid item xs={6} >
                  <TextField fullWidth placeholder='Enter unique slug' onChange={(e)=>setSlug(e.target.value)} value={slug} />  
                  <p style={{color:'red'}}>Note: Slug should be unique, same slugs will replace the previous uploads</p>
                </Grid>
                <Grid item xs={12} style={{display:'flex',justifyContent:'center'}}>
                    <label htmlFor="contained-button-file">
                        <Button variant='outlined' component="span" onClick={() => setmultipleImg(true)} style={{marginTop:'1rem'}}>Upload Images</Button>
                    </label>
                </Grid>
                  
                    
                  
                <Grid item xs={12} style={{display:'flex',justifyContent:'center'}}>
                    {!loading ?  <Button onClick={handleMultipleUpload} variant='outlined' component="span" style={{marginTop:'4rem'}}>Save</Button> : <CircularProgress/>}
                </Grid>
            </Grid>
            <DropzoneDialog
              acceptedFiles={['image/*']}
              cancelButtonText={"cancel"}
              submitButtonText={"submit"}
              maxFileSize={2097152}
              fileObjects={images}
              filesLimit={10}
              open={multipleImg}
              onClose={() => setmultipleImg(false)}
              onSave={(files) => {
                setImages(files)
                setmultipleImg(false)
              }}
              showPreviews={true}
              showFileNamesInPreview={true}
            />
        </React.Fragment>
    );
}

export default BulkImages;

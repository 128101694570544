import { Divider, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import React, { useEffect,useState } from 'react'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom'
import RichTextEditor from 'react-rte'
import { get, post, URL } from '../../services'

const alp =['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z']

function Details() {
    const [name, setname] = useState({en:'',de:''})
    const [letter, setletter] = useState('')
    const [cen, setcen] = React.useState(()=>RichTextEditor.createEmptyValue());
    const [cde, setcde] = React.useState(()=>RichTextEditor.createEmptyValue());
    const { enqueueSnackbar } = useSnackbar();
    const location = useLocation()

    useEffect(() => {
        let temp = location?.state?.data
        if(temp){
            getScienceByID()
        }
        
    }, [])
    console.log('id', location.state.data._id)
    const getScienceByID = async () => {  
        const dataToSend = {
            glossary_id: location.state.data._id,
          lang: 'en'
        }
        console.log('data to send', dataToSend) 
        await get(URL.getGlossaryByID,dataToSend).then((res)=> {
          console.log('dataaaa', res.data);  
          setname(res.data.name) 
          setletter(res.data.letter)
          res.data.content?.en ? setcen(()=>RichTextEditor.createValueFromString(res.data.content.en,'html')):null
          res.data.content?.en ? setcde(()=>RichTextEditor.createValueFromString(res.data.content.de,'html')):null  
        })
        .catch((e) => console.log('Error', e))
      }

    const handleSave = () => {
        console.log(cen.toString('html') , cde.toString('html'))
        if(!name.en || !name.de) return  enqueueSnackbar('Name is required',{
            variant:'success'
        })
        if(!letter) return  enqueueSnackbar('Please select letter',{
            variant:'success'
        })
        if(!cen.toString('html') || !cde.toString('html')) return  enqueueSnackbar('Content is required',{
            variant:'success'
        })
        let DataToSend = {
            glossary_id: location.state.data._id,
            name,
            content: {
                en: cen.toString('html'),
                de: cde.toString('html')
            },
            letter:letter
        }
        if( location?.state?.data?._id){
            DataToSend.glossary_id = location.state.data._id;
            EditGlossary(DataToSend)
        }else{
            AddGlossary(DataToSend)
        }
    }

    const AddGlossary = (data) =>{
        console.log('add',data)
       post(URL.addGlossary,data).then(res=>{
            enqueueSnackbar(res.msg,{
                variant:'success'
            })
       }).catch(err=>{
        const message = err.message || "Something went wrong";
            enqueueSnackbar(message,{
            variant:'error'
            })
       })
    }

   
    
    const EditGlossary = (data) =>{
        // console.log(data)
        // return
        post(URL.updateGlossary,data).then(res=>{
            enqueueSnackbar(res.msg,{
                variant:'success'
            })
        }).catch(err=>{
            const message = err.message || "Something went wrong";
            enqueueSnackbar(message,{
                variant:'error'
            })
        })
    }

    const handleName = (val,type)=>{
        let temp={...name}
        temp[type] = val
        setname(temp)
      }

    return (
        <React.Fragment>
      <Helmet title="HowItWorks" />
      <Typography variant="h3" gutterBottom display="inline">
      {/* name */}
      </Typography>
      <Divider my={6} />
      <br></br>  <br></br>
      <Grid justify="space-between" container spacing={6}>
        <Grid item>
          <Typography style={{ color: "#233044" }} variant="h3" gutterBottom>
          1.Details
          </Typography>
         </Grid>

        {/* <Grid item>
          <Button variant="outlined" >
            {" "}
            EDIT
          </Button>
        </Grid> */}
      </Grid>
      <Grid container spacing={3} style={{marginBottom:12}}>
          <Grid item xs={7}>
              <p><small>Name Eng</small></p>
              <TextField onChange={(e)=>handleName(e.target.value,'en')} value={name.en} fullWidth variant='outlined' />
          </Grid>
          <Grid item xs={7}>
                <p><small>Name German</small></p>
              <TextField onChange={(e)=>handleName(e.target.value,'de')} value={name.de} fullWidth variant='outlined'/>
          </Grid>
          <Grid item xs={7}>
            <FormControl fullWidth variant="outlined">
                <p><small>Name German</small></p>
                {/* <InputLabel id="demo-simple-select-outlined-label">Select Letter</InputLabel> */}
                <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                fullWidth
                value={letter}
                onChange={(e)=>setletter(e.target.value)}
                >
                    <MenuItem value="">
                        <em>Choose Letter</em>
                    </MenuItem>
                    {alp.map(letter=><MenuItem value={letter}>{letter}</MenuItem>)}
                </Select>
            </FormControl>
          </Grid>

      </Grid>
      <Grid item>
        <Divider style={{ color: "#233044" }} my={6} />
      </Grid>

    
      <div className="container-fluid mt-3">
      <div className="row">
        <div className="col-12">
           <h3 style={{color:"#233044"}}>2.Content</h3>
            <div className="mb-3">
                <RichTextEditor
                  value={cen}
                  onChange={setcen}
                />
              </div>
            <p style={{color:"#233044",marginTop:"17px"}}>How it Works DE</p>
            <div className="mb-3">
                <RichTextEditor
                  value={cde}
                  onChange={setcde}
                />
            </div>
            <br></br><br></br>
          <button type="button" onClick={handleSave} className="btn btn-primary first-primary">SAVE CHANGES</button>
          {/* <button type="button" className="btn btn-secondary second-button">CANCEL</button> */}

        </div>
       
     </div>
    </div>

  
    </React.Fragment>
    )
}
const style = {
    position: "absolute",
    padding: "40px",
    top: "50%",
    backgroundColor: "white",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: 700,
    // bgcolor: 'background.paper',
    border: "1px solid rgb(17, 24, 39)",
    boxShadow: 24,
    p: 4,
  };

export default Details

//  Import: React 
import React,{useState,useEffect} from 'react'
import { Helmet } from "react-helmet";

//  Import: React Redux 
import { useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import { store } from "../../redux/store/index";

//  Import: Styled Component
import styled, { withTheme } from "styled-components/macro";

//  Import: Npm Packages
import { useSnackbar } from 'notistack';
import { darken } from "polished";
import axios from "axios";
import moment from "moment";

//  Import: Internal Services
import { get, URL, headers, post ,langFun} from '../../services'

//  Import: Material Ui Core
import {
    Grid,
    Divider as MuiDivider,
    Typography as MuiTypography,
    InputBase,
    Switch,
    Snackbar,
    Backdrop,CircularProgress, TextField
    // Button,
  } from "@material-ui/core";

//  Import: Material Ui 
import { makeStyles } from "@material-ui/core/styles";
import { spacing } from "@material-ui/system";
import MaterialTable from "material-table";

const Divider = styled(MuiDivider)(spacing);
const label = { inputProps: { "aria-label": "Switch demo" } };

const useStyles = makeStyles((theme) => ({
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: "#fff" },
}));

const Typography = styled(MuiTypography)(spacing);  

// Modal Box Custom Style
const ModalBox = {
    position: "absolute",
    padding: "40px",
    top: "50%",
    backgroundColor: "white",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    // bgcolor: 'background.paper',
    border: "1px solid rgb(17, 24, 39)",
    boxShadow: 24,
    p: 4,
  };
// Search Box Custom Style
const Search = styled.div`
border-radius: 2px;
background-color: ${(props) => props.theme.header.background};
display: none;
position: relative;
width: 100%;
&:hover {
    background-color: ${(props) => darken(0.05, props.theme.header.background)};
}
${(props) => props.theme.breakpoints.up("md")} {
    display: block;
}
`;
// Search Icon Wrapper
const SearchIconWrapper = styled.div`
  width: 50px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 22px;
    height: 22px;
  }
`;
// Input Box Custom Style
const Input = styled(InputBase)`
  color: inherit;
  width: 100%;
  > input {
    color: ${(props) => props.theme.header.search.color};
    padding-top: ${(props) => props.theme.spacing(2.5)}px;
    padding-right: ${(props) => props.theme.spacing(2.5)}px;
    padding-bottom: ${(props) => props.theme.spacing(2.5)}px;
    padding-left: ${(props) => props.theme.spacing(12)}px;
    width: 160px;
  }
`;

const TableData = ({data,setFilter,FilteredData}) => {
    const lang = useSelector(state=>state.authReducer.lang ? state.authReducer.lang :'en')
    const history = useHistory();

    console.log('dara',data)
    const handleFilter =(val,feild)=>{
        if(val){
            let filter = []
            if(feild == 'first_name'){
                filter = data.filter(it=>it.user_id[feild].toLowerCase()?.includes(val))
            }else{
                filter = data.filter(it=>it[feild]?.toLowerCase().includes(val))
            }
            setFilter(filter)
        }else{
            setFilter([...data])
        }
    }
  return(
        <>
        <MaterialTable
              columns={[
                // Science Name
              
                // Science Category
                {
                  title: "ORDER NO.",
                  field: "orderno",
                  render: (row) => (
                    <div
                    //   onClick={() =>
                    //       history.push({
                    //         pathname: "/science/details",
                    //         state: { ...row ,action:"edit"},
                    //       })
                    //     }
                    >  
                        {!row.order_numb  ? <TextField placeholder='Search Order Num' onChange={(e)=>handleFilter(e.target.value,'order_numb')}/> : row.order_numb }
                    </div>
                  ),
                },
                // Science Created Date
                {
                    title: "CLIENT NAME",
                    field: "client",
                    type: "text",
                    render: (row) => (
                      <div
                    //   onClick={() =>
                    //     history.push({
                    //       pathname: "/science/details",
                    //       state: { ...row ,action:"edit"},
                    //     })
                    //   }
                      >
                    {!row.user_id  ? <TextField placeholder='Search by name' onChange={(e)=>handleFilter(e.target.value,'first_name')}/>  : row?.user_id?.first_name}
                      </div>
                    ),
                  },
                // Edit
                {
                  title: "CREATE DATE",
                  field: "create",
                  render: (row) => (
                    <div className="container">
                      {!row.createdAt ? '///' : moment(row?.createdAt).format('MM/DD/YYYY')}
                    </div>
                  ),
                },
                // Delete
                {
                  title: "TOTAL",
                  field: "total",
                  render: (row) => (
                    <div className="container">
                      {!row.total  ? '///' : parseFloat(row?.total).toFixed(2)}
                    </div>
                  ),
                },
                {
                  title: "STATUS",
                  field: "status",
                  render: (row) => (
                    <div className="container">
                       {!row.status ? '///' : row?.status}
                    </div>
                  ),
                },
                {
                  title: "ACTION",
                  field: "actions",
                  render: (row) => (
                    <div className="container">
                      {!row._id  ? '///' : <div className="row">
                        <div className="col-12 col-sm-5 col-md-5 col-lg-5 mt-1">
                            <i className="fas fa-eye cursor-pointer" 
                              onClick={() =>
                                history.push({
                                  pathname: "/orders/details",
                                  state: { ...row },
                                })
                              }
                            ></i>
                        </div>
                        <div className="col-12 col-sm-5 col-md-5 col-lg-5 mt-1">
                          <i
                            style={{ color: "rgb(17, 24, 39)" }}
                            className="fas fa-trash"
                            // onClick={()=>deleteScience(row)}
                          ></i>
                        </div>
                      </div>}
                    </div>
                  ),
                },
              ]}
              data={[{order:null,client:null,other:null},...FilteredData]}
              // title="Demo Title"
              components={{
                Toolbar: () => null,
              }}
              options={{
                // pageSizeOptions: [userdata.length, 5, 15, 30],
                // pageSize: userdata.length, // make initial page size
                tableLayout: "fixed",
                // pageSize: userdata.length, // make initial page size
                actionsColumnIndex: -1,
                search: false,
                showTitle: false,
                headerStyle: {
                  width: 15,
                  whiteSpace: "nowrap",
                  textAlign: "left",
                  flexDirection: "row",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  paddingLeft: 18,
                  paddingRight: 5,
                  backgroundColor: "rgb(17, 24, 39)",
                  fontWeight: "400",
                  color: "white",
                  // fontFamily:"Europa",
                  fontSize: "14px",
                },
                rowStyle: {
                  width: 50,
                  textAlign: "left",
                  flexDirection: "row",
                  paddingLeft: 2,
                  paddingRight: 2,
                  fontSize: "13px",
                  flexWrap: "wrap",
                },
              }}
            />
    </>
    )
}

function UserOrders({theme}) {
    // Hooks
    const [choice, setchoice] = useState(1);
    const lang = useSelector((state) => state.authReducer.lang); 
    const [orders, setorders] = useState([])
    const [ordersOnHold, setorderonHold] = useState([])
    const [filterOrders, setFilterOrders] = useState([])
    const [filterHold, setFilterHold] = useState([])

    const [state, setState] = useState({
        open1: false,
        vertical: 'top',
        horizontal: 'center',
      });
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [loader, setloader] = useState(false);
    
    const handleSnackBarClose = () => {
        setState({ ...state, open1: false });
    };

    const history = useHistory();

    useEffect(async () => { 
        await getOrders()
       
    }, []);

    const getOrders = async ()=>{
        let data = {
            lang:lang,
            offset:0,
            per_page:100
        }
       await get(URL.getUserOrder,data).then(res=>{
            let Allorders = [...res.data]
            console.log('allorder',Allorders)
            if(Allorders.length){
                let holdOrders = []
                let otherOrders = []
                Allorders.map((order,i)=>{
                    if(order.status == 'onHold'){
                        holdOrders.push(order)
                    }else{
                        otherOrders.push(order)
                    }
                })
                setorders(otherOrders)
                setFilterOrders(otherOrders)
                setorderonHold(holdOrders)
                setFilterHold(holdOrders)
                console.log(Allorders)
                console.log(holdOrders)
            }
        }).catch(err=>{
            console.log(err)
        })
    }
  
    
    return (
    <>
      <Helmet title="Analytics Dashboard" />
      <Backdrop className={classes.backdrop} open={loader}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid justify="space-between" container spacing={6}>
        <Grid item>
          <Typography
            style={{ color: "rgb(17, 24, 39)" }}
            variant="h3"
            gutterBottom
          >
            Orders
          </Typography>
        </Grid>
        {/* <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={open1}
          onClose={handleChangeSelect}
          message={msg}
          key={vertical + horizontal}
        /> */}
        {/* <Grid item>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <Input placeholder="Search topics" />
          </Search>
        </Grid> */}
      </Grid>
      <Grid item>
        <Divider style={{ color: "rgb(17, 24, 39)" }} my={6} />
      </Grid>
      <Grid justify="space-between" container spacing={6}>
        <Grid item>
        <div className="container mb-4">
        <div className="row row-cols-auto">
            <div className="col cursor-pointer" onClick={()=>setchoice(1)} style={{borderBottom:choice==1 ? "1px solid gray" : null,paddingBottom:"3px"}}>Completed</div>
            <div className="col cursor-pointer" onClick={()=>setchoice(2)} style={{borderBottom:choice==2 ? "1px solid gray" : null,paddingBottom:"3px"}}>On hold</div>
         </div>
        </div>
        </Grid>
      </Grid>
      {/* <TableData data={data} /> */}

        {
            choice == 1 ?
            // Science Section
            <div style={{ maxWidth: "100%" }}>
             <TableData data={orders} setFilter={setFilterOrders} FilteredData={filterOrders} />
          </div>
          :
            // Science Category Section
          <div style={{ maxWidth: "100%" }}>
            <TableData data={ordersOnHold} setFilter={setFilterHold}  FilteredData={filterHold}/>
          </div>
        }
   
    </>
  );
}

export default UserOrders

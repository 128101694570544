import React, { useState, useEffect } from "react";
import styled, { withTheme } from "styled-components/macro";

import { useSnackbar } from "notistack";

import { Helmet } from "react-helmet";
import { Search as SearchIcon } from "react-feather";
import {
  Grid,
  Divider as MuiDivider,
  Typography as MuiTypography,
  InputBase,
  Button,
  Switch,
  Modal,
  Box,
  TextField,
  InputLabel,
  // Input,
  ButtonGroup,
  FormHelperText,
  FormControl,
  Snackbar,
  Backdrop,
  CircularProgress,
  // Button,
} from "@material-ui/core";
import * as yup from "yup";
import { useFormik } from "formik";
import { darken } from "polished";
import { green, red } from "@material-ui/core/colors";

import { spacing } from "@material-ui/system";
import MaterialTable from "material-table";
import { Alert as MuiAlert } from "@material-ui/lab";
import { useSelector } from "react-redux";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { store } from "../../redux/store/index";
import axios from "axios";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";

const Alert = styled(MuiAlert)(spacing);
import { get, URL, headers, post, langFun } from "../../services";
const Divider = styled(MuiDivider)(spacing);
const label = { inputProps: { "aria-label": "Switch demo" } };

const useStyles = makeStyles((theme) => ({
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: "#fff" },
}));

const style = {
  position: "absolute",
  padding: "40px",
  top: "50%",
  backgroundColor: "white",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  // bgcolor: 'background.paper',
  border: "1px solid rgb(17, 24, 39)",
  boxShadow: 24,
  p: 4,
};
const style1 = {
  position: "absolute",
  padding: "40px",
  top: "50%",
  backgroundColor: "white",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  // bgcolor: 'background.paper',
  border: "1px solid rgb(17, 24, 39)",
  boxShadow: 24,
  p: 4,
};
const Typography = styled(MuiTypography)(spacing);
const Search = styled.div`
  border-radius: 2px;
  background-color: ${(props) => props.theme.header.background};
  display: none;
  position: relative;
  width: 100%;
  &:hover {
    background-color: ${(props) => darken(0.05, props.theme.header.background)};
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    display: block;
  }
`;

const SearchIconWrapper = styled.div`
  width: 50px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 22px;
    height: 22px;
  }
`;
const Input = styled(InputBase)`
  color: inherit;
  width: 100%;
  > input {
    color: ${(props) => props.theme.header.search.color};
    padding-top: ${(props) => props.theme.spacing(2.5)}px;
    padding-right: ${(props) => props.theme.spacing(2.5)}px;
    padding-bottom: ${(props) => props.theme.spacing(2.5)}px;
    padding-left: ${(props) => props.theme.spacing(12)}px;
    width: 160px;
  }
`;

const Error = styled.p`
  color: red;
  padding-top:8px;
  font-size: 10px;
`;

const validationSchema = yup.object({
  couponName: yup.string("Enter coupon name").required("Coupon name is required"),
  code: yup.string("Enter coupon code").required("Coupon code is required"),
  quantity: yup.string("Enter quantity").required("Please enter quantity"),
  discount: yup.string("Enter discout").required("Please enter discount"),
});
function CouponDetails({ theme }) {
  const authUser = useSelector((state) => state.authReducer);
  const location = useLocation();
  const TableCellStyle = { border: "1px solid red" };

  const [msg, setmsg] = React.useState("");

  const [state, setState] = React.useState({
    open1: false,
    vertical: "top",
    horizontal: "center",
  });
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [loader, setloader] = React.useState(false);
  const { vertical, horizontal, open1 } = state;
  const [details, setDetails] = useState(location.state);
  const [fData, setFData] = useState({
    couponName: location.state?.row?.coupon_name
      ? location.state?.row?.coupon_name
      : "",
    code: location.state?.row?.code ? location.state?.row?.code : "",
    couponId: location.state?.row?._id ? location.state?.row?._id : "",
    quantity: location.state?.row?.quantity
      ? location.state?.row?.quantity
      : "",
    active: location.state?.row?.active ? location.state?.row?.active : "",
    discount: location.state?.row?.discount_percent
      ? location.state?.row?.discount_percent
      : "",
  });
  const [initials, setinitials] = useState({
    couponName: location.state?.row?.coupon_name
      ? location.state?.row?.coupon_name
      : "",
    code: location.state?.row?.code ? location.state?.row?.code : "",
    couponId: location.state?.row?._id ? location.state?.row?._id : "",
    quantity: location.state?.row?.quantity
      ? location.state?.row?.quantity
      : "",
    active: location.state?.row?.active ? location.state?.row?.active : "",
    discount: location.state?.row?.discount_percent
      ? location.state?.row?.discount_percent
      : "",
  });
  const history = useHistory(); 

  const getCouponByID = async () => {  
    const dataToSend = {
      lang: 'en',
      coupon_id : location?.state?.row?._id,
    }
    let formikInitialValues = {};
    console.log('data to send', dataToSend) 
    formikInitialValues = await get(URL.getCoupounByID,dataToSend).then((res)=> {
      console.log('dataaaa', res.data);  
     return{
          couponName: res?.data?.coupon_name
            ? res?.data?.coupon_name
            : "",
          code: res?.data?.code ? res?.data?.code : "",
          couponId: res?.data?._id ? res?.data?._id : "",
          quantity: res?.data?.quantity
            ? res?.data?.quantity
            : "",
          active: res?.data?.active ? res?.data?.active : "",
          discount: res?.data?.discount_percent
            ? res?.data?.discount_percent
            : "", 
      }
    })
    .catch((e) => {
      console.log('Error', e)
    });
    setFData(formikInitialValues)
  } 
  
  useEffect(()=> {
    if(location?.state?.row?._id){
      getCouponByID()
    }
  },[])
  
  const formik = useFormik({
    initialValues: fData,
    validationSchema: validationSchema,
    onSubmit: (values, { setErrors, setStatus, setSubmitting }) => {
      // console.log(values)
      renderCouponChange(values, setErrors, setStatus, setSubmitting);
    },
    enableReinitialize: true 
  }); 

  useEffect(async () => {}, []);
  const handleClose1 = () => {
    setState({ ...state, open1: false });
  };
  const addCoupon = (values, setErrors, setStatus, setSubmitting) => {
    setSubmitting(true);

    const data = {
      //   coupon_id:values.couponId,
      // group_id:,
      name: values.couponName,
      code: values.code.toUpperCase(),
      percent: values.discount,
      quantity: values.quantity,
      lang: authUser.lang,
    };
    console.log("data", data);
    post(URL.addCoupon, data)
      .then((response) => {
        console.log("res", response);
        if (response.status) {
            setStatus({ success: true });
            enqueueSnackbar(response.msg,{
              variant:'success'
            })
        }
        setSubmitting(false);
      })
      .catch((err) => {
        const message = err?.msg || "Something went wrong";
        enqueueSnackbar(message,{
          variant:'error'
        })
        // setStatus({ success: false });
        // setErrors({ submit: message });
        // setSubmitting(false);
      });
  };
  const editCoupon = (values, setErrors, setStatus, setSubmitting) => {
    setSubmitting(true);

    const data = {
      coupon_id: values.couponId,
      name: values.couponName,
      code: values.code.toUpperCase(),
      percent: values.discount,
      quantity: values.quantity,
      lang: authUser.lang,
    };
    
    post(URL.editCoupoun, data)
      .then((response) => {
        if (response.status) {
            setStatus({ success: true });
            enqueueSnackbar(response.msg,{
              variant:'success'
            })
        }
        setSubmitting(false);
      })
      .catch((err) => {
        console.log("err", err);
        const message = err?.msg || "Something went wrong";
        enqueueSnackbar(message,{
          variant:'error'
        })
        // setStatus({ success: false });
        // setErrors({ submit: message });
        // setSubmitting(false);
      });
  };
  console.log({erroe:formik.errors})
  const renderCouponChange = (values, setErrors, setStatus, setSubmitting) => {
    console.log("location.sat", location.state);
    if (location.state == null) {
      addCoupon(values, setErrors, setStatus, setSubmitting);
    } else {
      editCoupon(values, setErrors, setStatus, setSubmitting);
    }
  };

  return (
    <React.Fragment>
      <Helmet title="Analytics Dashboard" />
      <Backdrop className={classes.backdrop} open={loader}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid justify="space-between" container spacing={6}>
        <Grid item>
          <Typography
            style={{ color: "rgb(17, 24, 39)" }}
            variant="h3"
            gutterBottom
          >
            Coupon Details
          </Typography>
        </Grid>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={open1}
          onClose={handleClose1}
          message={msg}
          key={vertical + horizontal}
        />
      </Grid>
      <Grid item>
        <Divider style={{ color: "rgb(17, 24, 39)" }} my={6} />
      </Grid>

      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="mb-3">
              <label htmlFor="exampleFormControlInput1" className="form-label">
                Coupon Name{" "}
              </label>
              <input
                name="couponName"
                type="text"
                value={formik?.values?.couponName}
                onChange={formik.handleChange}
                className="form-control"
                id="exampleFormControlInput1"
                placeholder="Coupon Name"
              />
              {formik.errors.couponName && formik.touched.couponName && (
                <Error>{formik.errors.couponName}</Error>
              )}
            </div>
            <div className="mb-3">
              <label htmlFor="exampleFormControlInput1" className="form-label">
                Coupon Code
              </label>

              <input
                name="code"
                type="text"
                value={formik.values?.code}
                onChange={formik.handleChange}
                className="form-control"
                id="exampleFormControlInput1"
                placeholder="Coupon Code"
              />
              {formik.errors.code && formik.touched.code && (
                <Error>{formik.errors.code}</Error>
              )}
            </div>
            <div className="mb-3">
              <label htmlFor="exampleFormControlInput1" className="form-label">
                Discount in %
              </label>

              <input
                name="discount"
                type="number"
                value={formik.values?.discount}
                onChange={formik.handleChange}
                className="form-control"
                id="exampleFormControlInput1"
                placeholder="Discount"
              />
              {formik.errors.discount && formik.touched.discount && (
                <Error>{formik.errors.discount}</Error>
              )}
            </div>
            <div className="mb-3">
              <label htmlFor="exampleFormControlInput1" className="form-label">
                Quantity
              </label>
              <input
                name="quantity"
                type="number"
                value={formik.values?.quantity}
                onChange={formik.handleChange}
                className="form-control"
                id="exampleFormControlInput1"
                placeholder="Quantitiy"
              />
              {formik.errors.quantity && formik.touched.quantity && (
                <Error>{formik.errors.quantity}</Error>
              )}
            </div>
            {/* {formik.errors.submit && (
              <Alert mt={2} mb={1} severity="warning">
                {formik.errors.submit}
              </Alert>
            )} */}
            <Button
              type="submit"
              variant="contained"
              color="primary"
              mt={3}
              // disables={formik.isSubmitting}
              onClick={formik.handleSubmit}
            >
              {location.state == null ? "Add" : "Save changes"}
            </Button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default withTheme(CouponDetails);